var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.length > 0)?_c('div',[_c('div',{staticClass:"jigsaw-carousel",on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{class:[
        'jigsaw-carousel__window',
        _vm.direction ? 'jigsaw-carousel__flow-' + _vm.direction : '',
        _vm.hover ? 'is-hovered' : '',
        _vm.aspect === 4/3 ? 'asp43' : ''
      ]},_vm._l((_vm.content),function(item,key){return _c('div',{key:key,class:[
          'jigsaw-carousel__item',
          _vm.index === key ? 'is-current' : '',
          _vm.nextIndex === key ? 'is-next' : '',
          _vm.prevIndex === key ? 'is-next' : '',
          _vm.active && _vm.index === key ? 'is-active' : '',
          _vm.active && _vm.nextIndex === key ? 'is-active' : '',
          _vm.active && _vm.prevIndex === key ? 'is-active' : '',
          _vm.moving && _vm.index === key ? 'is-moving' : '',
          _vm.moving && _vm.nextIndex === key ? 'is-moving' : '',
          _vm.moving && _vm.prevIndex === key ? 'is-moving' : ''
        ]},[_c('v-img',{attrs:{"src":item.path + '/' + _vm.location + '/' + item.image,"cover":"","aspect-ratio":_vm.aspect ? _vm.aspect : 16/9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"grey lighten-2 fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),0),_c('div',{staticClass:"jigsaw-carousel__prev"},[_c('v-btn',{attrs:{"rounded":"","icon":"","dark":"","elevation-1":"","disabled":_vm.moving},on:{"click":_vm.prev}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left mdi-18px')}})],1)],1),_c('div',{staticClass:"jigsaw-carousel__next"},[_c('v-btn',{attrs:{"rounded":"","icon":"","dark":"","elevation-1":"","disabled":_vm.moving},on:{"click":_vm.next}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-right mdi-18px')}})],1)],1),(_vm.delimiter)?_c('div',{staticClass:"jigsaw-carousel__delimiter"},_vm._l((_vm.content),function(item,dkey){return _c('v-btn',{key:dkey,class:[
          'jigsaw-carousel__delimiter-icon',
          'is-clickable',
          _vm.index === dkey ? 'is-active' : ''
        ],attrs:{"text":""},on:{"click":function($event){return _vm.gotoIndex(dkey)}}},[_c('v-icon',{attrs:{"dark":"","small":""},domProps:{"textContent":_vm._s('mdi-image')}})],1)}),1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }