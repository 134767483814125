<template>
  <div
    v-if="settings && crumbs && crumbs.length > 1"
    class="site-container"
  >
    <div class="py-1">
      <div
        v-if="crumbs.length > 3"
        class="d-inline-flex d-sm-none jigsaw-body-text pr-2"
        v-text="'...'"
      />
      <div
        v-for="(crumb, key) in crumbs"
        :key="key"
        class="jigsaw-body-text jigsaw-breadcrumb"
      >
        <span
          v-if="!crumb.disabled"
        >
          <router-link
            class="breadcrumb-link"
            :to="{ path: crumb.path }"
            exact-active-class=""
            active-class=""
            v-text="crumb.label"
          />
          <v-icon small>mdi-chevron-right</v-icon>
        </span>
        <span
          v-else
          class="breadcrumb-label"
        >
          <b>{{ crumb.label }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import forEach from 'lodash/forEach'
export default {
  name: 'Breadcrumb',
  data: () => ({
    crumbs: [],
    settings: false
  }),
  watch: {
    $route () {
      this.getCrumbs()
    }
  },
  created () {
    // Settings
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.settings = result

        this.getCrumbs()
      }, {
        immediate: true
      }
    )
  },
  methods: {
    getCrumbs () {
      let paths = this.$route.path.split('/')
      paths = paths.filter(Boolean)

      let crumbs = [
        {
          label: 'Home',
          disabled: true,
          path: '/'
        }
      ]

      let base = ''

      if (this.settings.search_url === '/' + paths[0]) {
        paths.pop()
      }

      if (this.settings.view_order_url === '/' + paths[0]) {
        paths.pop()
        paths.pop()
      }

      if (paths.length > 0) {
        crumbs[0].disabled = false
        forEach(paths, (item) => {
          base += '/' + item

          crumbs.push({
            label: item.replace(/-/g, ' '),
            disabled: false,
            path: base
          })
        })

        crumbs[crumbs.length - 1].disabled = true
      }

      if (crumbs.length === 1) {
        crumbs = []
      }

      this.crumbs = crumbs
    }
  }
}
</script>
