<template>
  <div v-if="value" v-html="value" />
</template>

<script>
export default {
  name: 'ContentTypeText',
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    value: false
  }),
  created () {
    // this.value = this.content ? this.content.value : false
    this.value = this.$sanitize(this.content.value, {
      allowedTags: ['p', 'em', 'strong', 'ul', 'ol', 'li', 'span'],
      allowedAttributes: {
        '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style']
      },
      allowedStyles: {
        '*': {
          // Match HEX and RGB
          color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
          'text-align': [/^left$/, /^right$/, /^center$/],
          'font-family': [/^assistant$/, /^Inkfree$/, /^sketch$/],
          // Match any number with px, em, or %
          'font-size': [/^\d+(?:px|em|%)$/]
        }
      }
    })
  }
}
</script>
