<template>
  <div class="header-menu">
    <div v-if="storeMenu && storeMenu.children && storeMenu.children.length > 0">
      <div class="d-flex align-center">
        <v-btn icon @click="handleStyleMenu()">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
    </div>
    <ul v-if="menu && menu.length > 0" class="main-menu">
      <li
        v-for="(item, key) in menu"
        :key="key"
        class="main-menu__parent"
      >
        <router-link
          :to="{ path: item.url }"
          class="navigation-link"
          active-class="is-active"
          exact-active-class="is-active"
        >
          <div>{{ item.title }}</div>
          <div v-if="item.children.length > 0">*</div>
        </router-link>
        <ul
          v-if="item.children.length > 0"
          class="main-menu__child"
        >
          <li
            v-for="(child, ckey) in item.children"
            :key="ckey"
            class="main-menu__child-item"
          >
            <div>{{ child.title }}</div>
          </li>
        </ul>
      </li>
    </ul>
    <div
      :class="[
        'style-menu',
        styleMenuState ? 'is-active' : ''
      ]"
    >
      <div class="style-menu__header d-flex align-center pa-3">
        <b class="jigsaw-card-text">{{ storeMenu.title }}</b>
        <v-spacer />
        <v-btn
          small
          color="primary"
          class="font-weight-bold"
          link :to="{ path: storeMenu.url }"
          v-text="'View All ' + storeMenu.title"
        />
      </div>
      <ul class="sub-menu">
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="pa-3 primary-list">
            <div
              v-for="(item, skey) in storeMenu.children"
              :key="skey"
              class="pa-0"
            >
              <v-btn
                :color="selectedStyle === skey ? 'primary' : ''"
                :dark="selectedStyle === skey"
                block
                depressed
                :class="[
                  'd-flex justify-space-between',
                  storeMenu.children.length > (skey + 1) ? 'mb-3' : ''
                ]"
                @click="selectedStyle = skey"
              >
                <b>{{ item.title }}</b>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <!-- <div>Info about the styles page</div>
              <v-btn small color="primary" link :to="{ path: storeMenu.url }">View All {{ storeMenu.title }}</v-btn> -->
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="pa-3"
          >
            <div
              v-if="selectedStyleObject && selectedStyleObject.image"
              class="sub-menu-header mt-n3 mx-n3"
            >
              <v-img
                :src="'/images/styles/' + selectedStyleObject.image"
                cover
                :aspect-ratio="1120/100"
                lazy-src="https://picsum.photos/id/11/100/60"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <div class="sub-menu-header__title jigsaw-card-title-text grey--text text--lighten-5">
                <div class="d-flex align-center px-3 height-100">
                  {{ selectedStyleObject.title }}
                </div>
              </div>
            </div>
            <div class="py-2">
              {{ getInfoSnippet(selectedStyleObject.info, 120) }}
            </div>
            <v-row>
              <v-col cols="8">Featured Plans</v-col>
              <v-col cols="4">&nbsp;</v-col>
              <v-col
                v-for="(plan, pkey) in selectedStyleObject.plans"
                :key="pkey"
                cols="4"
              >
                <v-card
                  class="jigsaw__plan-card"
                  link
                  :to="{ path: storeMenu.url + selectedStyleObject.url + plan.url }"
                >
                  <v-img
                    v-if="plan && plan.media && plan.media.length > 0"
                    :src="plan.media[0].path + '/medium/' + plan.media[0].image"
                    cover
                    :aspect-ratio="16/9"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img
                    v-else
                    :aspect-ratio="16/9"
                    class="grey lighten-2"
                  >
                    <template v-slot:default>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <div>
                          <v-icon
                            x-large
                            color="grey darken-2"
                            v-text="'far fa-image'"
                          />
                        </div>
                      </v-row>
                    </template>
                  </v-img>
                  <v-card-text>
                    {{ plan.title }}
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                cols="4"
              >
                <v-card
                  class="jigsaw__plan-card height-100 d-flex align-center justify-center flex-column"
                  link
                  :to="{ path: storeMenu.url + selectedStyleObject.url }"
                >
                  <div class="h4 text-capitalize px-4 text-center">View all {{ selectedStyleObject.title }} {{ storeMenu.title }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </ul>
    </div>
    <v-spacer />
    <v-menu
      :offset-y="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey darken-3"
          class="px-2"
          dark
          text
          v-bind="attrs"
          v-on="on"
        >
          <div class="d-flex align-center">
            <Icons class="mr-2" :iconName="selectedCurrency.icon" />
            <v-icon class="" v-text="'mdi-chevron-down'" />
          </div>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in currencyOptions"
          :key="index"
        >
          <v-list-item-title>
            <div class="d-flex align-center is-clickable" @click="changeCurrency(item.code)">
              <Icons class="mr-2" :iconName="item.icon" />
              <div class="text-capitalize" v-text="item.label" />
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import general from '../utils/general/general'
import Icons from '../components/icons/icon'
export default {
  name: 'MainMenu',
  components: {
    Icons
  },
  mixins: [general],
  data: () => ({
    menu: [],
    storeMenu: [],
    blogMenu: [],
    //
    styleMenuState: false,
    selectedStyle: -1,
    selectedStyleObject: {},
    //
    currency: 'GBP',
    currencyOptions: [
      { code: 'GBP', icon: 'gbp-flag', symbol: '£', label: 'Pound Sterling' },
      { code: 'CAD', icon: 'cad-flag', symbol: '$', label: 'Canadian Dollar' },
      { code: 'USD', icon: 'usd-flag', symbol: '$', label: 'US Dollar' },
      { code: 'AUD', icon: 'aud-flag', symbol: '$', label: 'Austrailian Dollar' },
      { code: 'NZD', icon: 'nzd-flag', symbol: '$', label: 'New Zealand Dollar' },
      { code: 'EUR', icon: 'eur-flag', symbol: '€', label: 'Euro' }
    ],
    selectedCurrency: false
  }),
  watch: {
    selectedStyle: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (oldValue !== newValue) {
          if (newValue > -1) {
            this.selectedStyleObject = this.storeMenu.children[newValue]
          } else {
            this.selectedStyleObject = {}
          }
        }
      }
    }
  },
  created () {
    this.$store.watch(
      (state, getters) => getters.getSiteNavigation, (result) => {
        this.processData(result)
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getNavDrawerState, (result) => {
        this.styleMenuState = result
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCurrency, (result) => {
        this.currency = result

        this.updateSelectedCurrency()
      }, {
        immediate: true
      }
    )
  },
  methods: {
    processData (source) {
      if (source) {
        source.forEach(element => {
          if (element && element.type === 'store') {
            this.storeMenu = element
          } else if (element && element.type === 'blog') {
            this.blogMenu = element
          } else {
            this.menu.push(element)
          }
        })

        if (this.storeMenu && this.storeMenu.children && this.storeMenu.children.length > 0) {
          this.selectedStyle = 0
        }
      }
    },
    //
    handleStyleMenu () {
      this.$store.commit('setNavDrawerState', !this.styleMenuState)
    },
    //
    updateSelectedCurrency () {
      this.selectedCurrency = this.currencyOptions.find(o => o.code === this.currency)
      this.$store.commit('setCurrencySymbol', this.selectedCurrency.symbol)
    },
    changeCurrency (option) {
      this.$store.commit('setCurrency', option)
    }
  }
}
</script>
