<template>
  <div>
    <img
      ref="imageElement"
      :src="content.src.url"
      :style="buildStyles()"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentTypeImage',
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    value: false
  }),
  created () {
    this.value = this.content ? this.content.value : false
  },
  methods: {
    buildStyles () {
      const src = this.content.src
      const styling = {}
      // autoWidth
      if (src.autoWidth) {
        styling.width = '100%'
        styling.maxWidth = src.width + 'px'
      } else {
        styling.width = src.maxWidth + '%'
        styling.maxWidth = src.maxWidth + '%'
      }

      return styling
    }
  }
}
</script>
