<template>
  <div>
    <v-row>
      <v-col
        v-for="(cat, key) in styles"
        :key="key"
        cols="12"
        sm="6"
      >
        <v-card
          class="jigsaw__plan-card"
          link
          :to="{ path: links.store_url + cat.url }"
        >
          <v-img
            v-if="cat && cat.image"
            :src="'/images/styles/' + cat.image"
            cover
            :aspect-ratio="1120/300"
            lazy-src="https://picsum.photos/id/11/100/60"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <!-- {{ cat }} -->
          <v-card-title>
            {{ cat.title }} ({{ getPlanCount(cat.plans) }})
          </v-card-title>

          <v-card-text class="d-flex justify-center">
            <v-btn
              outlined
              :to="{ path: links.store_url + cat.url }"
            >
              View Styles
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getData } from '@/utils/api/api'
import general from '@/utils/general/general'
export default {
  name: 'DynamicCategories',
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    },
    params: {
      type: [Boolean, Object],
      default: false
    }
  },
  mixins: [general],
  data: () => ({
    loaded: false,
    links: false,
    //
    styles: []
  }),
  created () {
    this.$store.watch(
      (state, getters) => getters.getSiteLinks, (result) => {
        this.links = result
      }, {
        immediate: true
      }
    )

    getData('plans/getStyles').then(response => {
      if (response.data) {
        this.styles = response.data
      }

      this.loaded = true
    })

    if (this.params && this.params.pageTitle) {
      this.$store.commit('setPageTitle', this.params.pageTitle)
    }
  },
  methods: {
    getPlanCount (plans) {
      return this.getArraySize(plans)
    }
  }
}
</script>
