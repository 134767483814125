<template>
  <main>
    <div class="site-container">
      <div
        v-if="pageTitle !== ''"
        class="jigsaw-text-theme2 jigsaw-text-title jigsaw-text-script pt-3 pb-3"
        v-text="pageTitle"
      />

      <div v-if="rows.length > 0">
        <v-row
          v-for="(row, key) in rows"
          :key="key"
          :style="getStyling(row)"
        >
          <v-col
            v-for="(column, colkey) in row.columns"
            :key="colkey"
            cols="12"
            :sm="row.cells[colkey] * 3"
          >
            <div
              v-for="(content, ckey) in column.contents"
              :key="ckey"
              :class="['rte']"
              :style="getStyling(content)"
            >
              <ContentText v-if="content.type === 'text'" :content="content" />
              <ContentHeading v-if="content.type === 'heading'" :content="content" />
              <ContentImage v-if="content.type === 'image'" :content="content" />
              <ContentVideo v-if="content.type === 'video'" :content="content" />
              <DynamicContent
                v-if="content.type === 'dynamic'"
                :content="content"
                :params="params"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </main>
</template>

<script>
import { postDataObj } from '@/utils/api/api'
import styling from '@/utils/styling/styling'

import ContentText from '../components/contentTypes/text'
import ContentHeading from '../components/contentTypes/heading'
import ContentImage from '../components/contentTypes/image'
import ContentVideo from '../components/contentTypes/video'
import DynamicContent from '../components/dynamic/dynamic'
export default {
  name: 'PageContent',
  components: {
    ContentText,
    ContentHeading,
    ContentImage,
    ContentVideo,
    DynamicContent
  },
  mixins: [styling],
  data: () => ({
    pageContent: false,
    params: false,
    rows: [],
    //
    pageTitle: ''
  }),
  created () {
    this.getContent()

    this.$store.watch(
      (state, getters) => getters.getPageTitle, (result) => {
        this.pageTitle = result
      }, {
        immediate: true
      }
    )
  },
  methods: {
    getContent () {
      const params = this.$route.params

      postDataObj('cms/getPage', params).then(response => {
        if (response.data) {
          this.params = response.data.params
          this.pageContent = response.data.content
          this.rows = response.data.content.content

          if (this.params.type === 'page') {
            this.$store.commit('setPageTitle', this.pageContent.title)
          } else {
            this.$store.commit('setPageTitle', '')
          }
        }
      })
    }
  }
}
</script>
