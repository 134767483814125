<template>
  <v-app class="jigsaw">
    <Header />
    <Breadcrumb />
    <v-main>
      <transition
        name="fade"
        mode="out-in">
        <router-view :key="$route.path" />
      </transition>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import { getData } from '@/utils/api/api'
import Header from './partials/header'
import Footer from './partials/footer'
import Breadcrumb from './components/breadcrumb/breadcrumb'
export default {
  name: 'App',

  components: {
    Header,
    Footer,
    Breadcrumb
  },

  data: () => ({
    //
    acceptedCurrency: ['AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'USD']
  }),
  watch: {
    $route (to, from) {
      // react to route changes...
      if (to.path !== from.path) {
        // this.drawer = false
        this.$store.commit('setNavDrawerState', false)
      }
    }
  },
  created () {
    const url = 'https://api.exchangerate-api.com/v4/latest/GBP'

    // https://open.er-api.com/v6/latest/

    fetch(url)
      .then(res => res.json())
      .then((out) => {
        const rates = {
          GBP: 1,
          USD: out.rates.USD,
          CAD: out.rates.CAD,
          AUD: out.rates.AUD,
          NZD: out.rates.NZD,
          EUR: out.rates.EUR
        }
        this.$store.commit('setExchangeRates', rates)
      })
      .catch(err => { throw err })

    getData('settings/getSiteCore').then(response => {
      if (response.data.core) {
        this.$store.commit('setCoreSettings', response.data.core)
      }

      if (response.data.navigation) {
        this.$store.commit('setSiteNavigation', response.data.navigation)
      }

      if (response.data.links) {
        this.$store.commit('setSiteLinks', response.data.links)
      }

      if (response.data.geo) {
        if (this.acceptedCurrency.includes(response.data.geo.geoplugin_currencyCode)) {
          this.$store.commit('setCurrency', response.data.geo.geoplugin_currencyCode)
          this.$store.commit('setCurrencySymbol', response.data.geo.geoplugin_currencySymbol)
        }
      }
    })
  }
}
</script>
<style lang="scss">
@import '../scss/styles';
</style>
