<template>
  <footer class="footer">
    <div class="site-container">
      Footer
    </div>

    <div class="sub-footer">
      <div class="site-container">
        Copyright info
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  components: {}
}
</script>
