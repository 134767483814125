import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '@/content/content'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior (to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Content
    },
    {
      path: '/:urla',
      name: 'Content',
      component: Content
    },
    {
      path: '/:urla/:urlb',
      name: 'ContentPlus',
      component: Content
    },
    {
      path: '/:urla/:urlb/:urlc',
      name: 'ContentExtra',
      component: Content
    },
    {
      path: '/:urla/:urlb/:urlc/:urld',
      name: 'ContentExtraPlus',
      component: Content
    }
  ]
})
