<template>
  <v-row>
    <v-col
      cols="12"
      sm="9"
    >
      <!-- {{ plan }} -->
      <div class="h2 font-bold">
        {{ plan.title }}
      </div>
      <div class="alternative-text h5 font-bold font-space-1">
        {{ plan.tagline }}
      </div>
      <div>
        Media:
        <PlanCarousel
          v-if="plan && plan.media && plan.media.length > 1"
          :content="plan.media"
          location="original"
          :aspect="4/3"
          :delimiter="true"
          :cycle="true"
        />
        <v-img
          v-else-if="plan && plan.media && plan.media.length > 0"
          :src="plan.media[0].path + '/original/' + plan.media[0].image"
          cover
          :aspect-ratio="4/3"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-img
          v-else
          :aspect-ratio="4/3"
          class="grey lighten-2"
        >
          <template v-slot:default>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <div>
                <v-icon
                  x-large
                  color="grey darken-2"
                  v-text="'far fa-image'"
                />
              </div>
            </v-row>
          </template>
        </v-img>
      </div>
      <div>
        Floor Plans:
        <PlanCarousel
          v-if="plan && plan.floor_plans && plan.floor_plans.length > 1"
          :content="plan.floor_plans"
          location="original"
          :aspect="4/3"
          :delimiter="false"
        />
      </div>

      <div>
        Rooms:
        <!-- <pre>{{ plan.room_list }}</pre> -->
        <div v-for="(floor, rlKey) in plan.room_list" :key="rlKey">
          <b>{{ rlKey }}</b>
          <v-row>
            <v-col cols="7">Room</v-col>
            <v-col cols="3" class="text-center">Size</v-col>
            <v-col cols="2" class="text-center">Height</v-col>
          </v-row>
          <v-row v-for="(room, rKey) in floor" :key="rKey">
            <v-col cols="7" class="py-1">{{ room.title }}</v-col>
            <v-col cols="3" class="py-1 text-center">{{ room.size }}</v-col>
            <v-col cols="2" class="py-1 text-center">{{ room.height }}</v-col>
          </v-row>
        </div>
      </div>
      <div>
        General Specifications:
        <!-- <pre>{{ plan.tech_spec }}</pre> -->
        <pre>{{ plan.spec_list }}</pre>
      </div>
      <div>
        Information:
        <div v-html="$sanitize(plan.information)" />
      </div>
      <div>
        Tags: {{ plan.tags }}
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="3"
    >
      <div>
        Bedrooms {{ plan.beds }}
      </div>
      <div>
        Bathrooms {{ plan.baths }}
      </div>
      <div>
        Floors {{ plan.floors }}
      </div>
      <div>
        Length {{ plan.length }}
      </div>
      <div>
        Width {{ plan.width }}
      </div>
      <div>
        Total Living Area (sq. ft.) {{ plan.area }}
      </div>
      <div>
        Description:
        <div v-html="$sanitize(plan.description)" />
      </div>
      <div class="secondary-text h3 font-bold font-space-1">
        {{ currencySymbol + formatPlanCurrency(plan.price * rates[currency]) }}
      </div>
      <v-icon v-text="'fas fa-car'" />
      <v-icon v-text="'fas fa-tape'" />
      <v-icon v-text="'fas fa-bed'" />
      <v-icon v-text="'fas fa-bath'" />
      <v-icon v-text="'mdi-stairs-up'" />
    </v-col>
  </v-row>
</template>

<script>
import { postDataObj } from '@/utils/api/api'
import general from '@/utils/general/general'
import PlanCarousel from '@/components/carousels/plan-carousel'
export default {
  name: 'DynamicPlanDetails',
  components: {
    PlanCarousel
  },
  mixins: [general],
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    },
    params: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    loaded: false,
    id: -1,
    plan: false,
    //
    currency: 'GBP',
    currencySymbol: '£',
    rates: false
  }),
  created () {
    this.id = this.params.id

    postDataObj('plans/getPlan', { id: this.id }).then(response => {
      if (response.data) {
        this.processData(response.data)
      } else {
        this.loaded = true
      }
    })

    this.$store.watch(
      (state, getters) => getters.getExchangeRates, (result) => {
        this.rates = result
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCurrency, (result) => {
        this.currency = result
      }, {
        immediate: true
      }
    )

    this.$store.watch(
      (state, getters) => getters.getCurrencySymbol, (result) => {
        this.currencySymbol = result
      }, {
        immediate: true
      }
    )
  },
  methods: {
    processData (source) {
      if (source) {
        this.plan = this.processPlan(source)
      }
    }
  }
}
</script>
