export default {
  methods: {
    // Generic
    IsJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getArrayContent (arr) {
      if (this.IsJsonString(arr) && arr && typeof arr === 'string') {
        return JSON.parse(arr)
      }

      if (typeof arr === 'object') {
        return arr
      }

      return []
    },
    getArraySize (arr) {
      if (this.IsJsonString(arr) && arr) {
        const data = JSON.parse(arr)
        return data.length
      }

      return 0
    },
    //
    getCarousel (item) {
      let carousel = []
      if (item && item.media && item.media.length > 0) {
        const arr = []
        arr.push(item.media[0])

        if (item && item.floor_plans && item.floor_plans.length > 0) {
          carousel = [...arr, ...item.floor_plans]
        } else {
          carousel = false
        }
      }

      return carousel
    },
    //
    processPlan (plan) {
      if (plan) {
        if (plan.floor_plans) {
          plan.floor_plans = this.getArrayContent(plan.floor_plans)
        }

        if (plan.likes) {
          plan.likes = this.getArrayContent(plan.likes)
        }

        if (plan.media) {
          plan.media = this.getArrayContent(plan.media)
        }

        if (plan.tags) {
          plan.tags = this.getArrayContent(plan.tags)
        }

        if (plan.rooms) {
          plan.rooms = this.getArrayContent(plan.rooms)
          plan.room_list = this.processPlanRooms(plan.rooms)
        }

        if (plan.tech_spec) {
          plan.tech_spec = this.getArrayContent(plan.tech_spec)
          plan.spec_list = this.processPlanSpecs(plan.tech_spec)
        }

        return plan
      }
    },
    processPlanSpecs (specs) {
      const planSpecs = {}
      specs.forEach(spec => {
        const isin = spec.group in planSpecs
        if (!isin) {
          planSpecs[spec.group] = []
        }
        planSpecs[spec.group].push({
          title: spec.title,
          info: spec.info
        })
      })

      return planSpecs
    },
    processPlanRooms (specs) {
      const planSpecs = {}
      specs.forEach(spec => {
        const isin = spec.floor in planSpecs
        if (!isin) {
          planSpecs[spec.floor] = []
        }
        planSpecs[spec.floor].push({
          title: spec.title,
          size: spec.size,
          height: spec.height
        })
      })

      return planSpecs
    },
    //
    getInfoSnippet (str, len) {
      if (str && str !== '') {
        if (str.length > len) {
          var i = str.indexOf(' ', len)
          return str.substring(0, i)
        }
        return str
      } else {
        return str
      }
    },
    // Function to round up plans via exchange rate to the nearest whole number and return a curency value
    formatPlanCurrency (value) {
      if (value) {
        const newVal = Math.round(parseFloat(value))
        return parseFloat(newVal).toFixed(2)
      }
      return parseFloat('0.00').toFixed(2)
    }
  }
}
