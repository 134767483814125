<template>
  <div
    v-if="content.video.type === 'youtube'"
    class="video-container"
  >
    <iframe
      :src="'https://www.youtube.com/embed/' + content.video.id + '?controls=' + content.video.controls + '?autoplay=' + content.video.autoplay + '?loop=' + content.video.loop"
      frameborder="0"
      allowfullscreen
    />
  </div>
  <div
    v-else-if="content.video.type === 'vimeo'"
    class="video-container"
  >
    <vueVimeoPlayer ref="player" :video-id="content.video.id"></vueVimeoPlayer>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'ContentTypeVideo',
  components: { vueVimeoPlayer },
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    }
  },
  created () {
  }
}
</script>
