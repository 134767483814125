<template>
  <v-row v-if="loaded" class="mb-3">
    <v-col
      v-for="(plan, key) in plans"
      :key="key"
      cols="12"
      sm="6"
    >
      <v-card
        class="jigsaw__plan-card"
        link
        :to="{ path: planLinkBase + plan.url }"
      >
        <v-card-title
          class="d-flex align-baseline justify-space-between"
        >
          <div>
            <router-link
              class="jigsaw__plan-card-title text--primary"
              :to="{ path: planLinkBase + plan.url }"
              v-text="plan.title"
            />
            <span
              v-if="plan.plan_ref !== ''"
              class="text-caption ml-3"
              v-text="'(' + plan.plan_ref + ')'"
            />
          </div>
          <!-- Likes
          <v-icon>mdi-heart</v-icon>
          <v-icon>mdi-heart-outline</v-icon> -->
          <v-btn
            outlined
            x-small
            fab
          >
            <v-icon>mdi-heart-outline</v-icon>
          </v-btn>
        </v-card-title>
        <PlanCarousel
          v-if="plan && plan.carousel && plan.carousel.length > 1"
          :content="plan.carousel"
          :aspect="16/9"
        />
        <v-img
          v-else-if="plan && plan.media && plan.media.length > 0"
          :src="plan.media[0].path + '/medium/' + plan.media[0].image"
          cover
          :aspect-ratio="16/9"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-img
          v-else
          :aspect-ratio="16/9"
          class="grey lighten-2"
        >
          <template v-slot:default>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <div>
                <v-icon
                  x-large
                  color="grey darken-2"
                  v-text="'far fa-image'"
                />
              </div>
            </v-row>
          </template>
        </v-img>
        <v-card-text class="pa-0">
          <v-row class="jigsaw-column-separators" no-gutters>
            <v-col cols="3" class="text-center py-2">
              <div class="plan__subtext">BEDROOMS</div>
              <div class="plan__sketch">{{ plan.beds }}</div>
            </v-col>
            <v-col cols="3" class="text-center py-2">
              <div class="plan__subtext">BATHROOMS</div>
              <div class="plan__sketch">{{ plan.baths }}</div>
            </v-col>
            <v-col cols="3" class="text-center py-2">
              <div class="plan__subtext">LIVING AREA</div>
              <div class="plan__sketch">{{ plan.area }} sq.ft.</div>
            </v-col>
            <v-col cols="3" class="text-center py-2">
              <div class="plan__subtext">FLOORS</div>
              <div class="plan__sketch">{{ plan.floors }}</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn outlined class="elevation-0 my-3">Details</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { postDataObj } from '@/utils/api/api'
import general from '@/utils/general/general'
import PlanCarousel from '../components/carousels/plan-carousel'
export default {
  name: 'DynamicPlanList',
  components: {
    PlanCarousel
  },
  mixins: [general],
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    },
    params: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    loaded: false,
    links: false,
    //
    style: false,
    plans: [],
    planLinkBase: ''
  }),
  created () {
    this.planLinkBase = this.$route.fullPath
    if (this.params && this.params.pageTitle) {
      this.$store.commit('setPageTitle', '')
    }

    if (this.params && this.params.id) {
      this.getPlans(this.params.id)
    }
  },
  beforeDestroy () {
    this.$store.commit('setPlanCategory', false)
  },
  methods: {
    processData (source) {
      if (source) {
        const plans = source.plans
        plans.forEach(item => {
          item.media = this.getArrayContent(item.media)
          item.floor_plans = this.getArrayContent(item.floor_plans)
          item.rooms = this.getArrayContent(item.rooms)
          item.tech_spec = this.getArrayContent(item.tech_spec)
          item.tags = this.getArrayContent(item.tags)
          item.planLikes = this.getArrayContent(item.likes)

          item.carousel = this.getCarousel(item)
        })

        this.plans = plans
        this.style = source.style

        this.$store.commit('setPlanCategory', source.style)

        setTimeout(() => {
          this.loaded = true
        }, 1)
      }
    },
    getPlans (id) {
      if (id) {
        postDataObj('plans/getStylePlans', { id: id }).then(response => {
          if (response.data) {
            this.processData(response.data)
          } else {
            this.loaded = true
          }
        })
      }
    },
    getPlanlike (plans) {
      return this.getArraySize(plans)
    }
  }
}
</script>
