<template>
  <div v-if="content && content.length > 0">
    <div
      class="jigsaw-carousel"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div
        :class="[
          'jigsaw-carousel__window',
          direction ? 'jigsaw-carousel__flow-' + direction : '',
          hover ? 'is-hovered' : '',
          aspect === 4/3 ? 'asp43' : ''
        ]"
      >
        <div
          v-for="(item, key) in content"
          :key="key"
          :class="[
            'jigsaw-carousel__item',
            index === key ? 'is-current' : '',
            nextIndex === key ? 'is-next' : '',
            prevIndex === key ? 'is-next' : '',
            active && index === key ? 'is-active' : '',
            active && nextIndex === key ? 'is-active' : '',
            active && prevIndex === key ? 'is-active' : '',
            moving && index === key ? 'is-moving' : '',
            moving && nextIndex === key ? 'is-moving' : '',
            moving && prevIndex === key ? 'is-moving' : ''
          ]"
        >
          <!-- {{ key }} -->
          <v-img
            :src="item.path + '/' + location + '/' + item.image"
            cover
            :aspect-ratio="aspect ? aspect : 16/9"
          >
            <template v-slot:placeholder>
              <v-row
                class="grey lighten-2 fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
      <div class="jigsaw-carousel__prev">
        <v-btn
          rounded
          icon
          dark
          elevation-1
          :disabled="moving"
          @click="prev"
        >
          <v-icon v-text="'mdi-arrow-left mdi-18px'" />
        </v-btn>
      </div>
      <div class="jigsaw-carousel__next">
        <v-btn
          rounded
          icon
          dark
          elevation-1
          :disabled="moving"
          @click="next"
        >
          <v-icon v-text="'mdi-arrow-right mdi-18px'" />
        </v-btn>
      </div>
      <div
        v-if="delimiter"
        class="jigsaw-carousel__delimiter"
      >
        <v-btn
          v-for="(item, dkey) in content"
          :key="dkey"
          :class="[
            'jigsaw-carousel__delimiter-icon',
            'is-clickable',
            index === dkey ? 'is-active' : ''
          ]"
          text
          @click="gotoIndex(dkey)"
        >
          <v-icon
            dark
            small
            v-text="'mdi-image'"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanCarousel',
  props: {
    content: {
      type: [Boolean, Array],
      default: false
    },
    location: {
      type: String,
      default: 'medium'
    },
    aspect: {
      type: [Boolean, Number, String],
      default: false
    },
    thumbs: {
      type: [Boolean],
      default: false
    },
    delimiter: {
      type: [Boolean],
      default: false
    },
    cycle: {
      type: [Boolean],
      default: false
    },
    interval: {
      type: [Boolean, Number],
      default: 8
    }
  },
  data: () => ({
    index: 0,
    direction: false,
    active: false,
    moving: false,
    hover: false,
    nextIndex: -1,
    prevIndex: -1,
    speed: 1000
  }),
  created () {
    this.size = this.content.length

    if (this.cycle) {
      setInterval(() => {
        if (!this.hover) {
          if (!this.moving) {
            this.next()
          }
        }
      }, this.interval * 1000)
    }
  },
  methods: {
    // nextPrev () {
    //   let next = this.index + 1
    //   let prev = this.index - 1
    //   if (next >= this.size) {
    //     next = 0
    //   }

    //   if (prev < 0) {
    //     prev = this.size - 1
    //   }

    //   this.nextIndex = next
    //   this.prevIndex = prev
    // },
    next (e) {
      if (e) {
        e.preventDefault()
      }
      this.resetTrans()
      this.direction = 'rtl'
      let next = this.index + 1
      if (next >= this.size) {
        next = 0
      }

      this.nextIndex = next
      this.active = true

      setTimeout(() => {
        this.moving = true
      }, 0)

      setTimeout(() => {
        this.index = next
        this.resetTrans()
      }, this.speed)
    },
    prev (e) {
      if (e) {
        e.preventDefault()
      }
      this.resetTrans()
      this.direction = 'ltr'
      let prev = this.index - 1
      if (prev < 0) {
        prev = this.size - 1
      }

      this.prevIndex = prev
      this.active = true

      setTimeout(() => {
        this.moving = true
      }, 0)

      setTimeout(() => {
        this.index = prev
        this.resetTrans()
      }, this.speed)
    },
    gotoIndex (index) {
      this.index = index
    },
    resetTrans () {
      this.beforeEnter = -1
      //
      this.beforeLeave = -1
      //
      this.nextIndex = -1
      this.prevIndex = -1
      //
      this.moving = false
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/init";
$carousel-speed: 1s;

$jigsaw-transition: .6s cubic-bezier(.25, .8, .50, 1) !default;

.jigsaw-carousel {
  overflow: hidden;
  position: relative;

  &:hover {
    .jigsaw-carousel__next,
    .jigsaw-carousel__prev {
      transform: translateX(0);
    }
  }
}

.jigsaw-carousel__delimiter {
  align-items: center;
  background-color: rgba(0, 0, 0, .25);
  bottom: 0;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.jigsaw-carousel__delimiter-icon {
  &,
  &.btn {
    @include animate(opacity);
    align-items: center;
    background: rgba(255, 255, 255, .5);
    border-radius: 50%;
    display: flex;
    height: 25px !important;
    justify-content: center;
    margin: 0 8px;
    opacity: .6 !important;
    padding: 0 !important;
    width: 25px !important;
    min-width: 25px !important;

    &.is-active,
    &:hover {
      opacity: 1 !important;
    }
  }
}

.jigsaw-carousel__window {
  @include aspect(16, 9);
  overflow: hidden;

  &.asp43 {
    @include aspect(4, 3);
  }
}

.jigsaw-carousel__next,
.jigsaw-carousel__prev {
  @include animate(transform);
  background: rgba(0,0,0,.3);
  border-radius: 50%;
  position: absolute;
  margin: 0 16px;
  top: calc(50% - 20px);
  z-index: 4;
}

.jigsaw-carousel__next {
  right: 0;
  transform: translateX(200%);
}

.jigsaw-carousel__prev {
  left: 0;
  transform: translateX(-200%);
}

.jigsaw-carousel__item {
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(0);
  width: 100%;
  z-index: 1;

  &.is-active {
    @include animate(transform, $carousel-speed);
    display: block;
    z-index: 2;
  }

  &.is-current {
    display: block;
    z-index: 3;
  }
}

.jigsaw-carousel__flow-rtl {
  .is-next {
    transform: translateX(100%);
  }

  .is-moving {
    &.is-current {
      transform: translateX(-100%);
    }

    &.is-next {
      transform: translateX(0);
    }
  }
}

.jigsaw-carousel__flow-ltr {
  .is-next {
    transform: translateX(-100%);
  }

  .is-moving {
    &.is-current {
      transform: translateX(100%);
    }

    &.is-next {
      transform: translateX(0);
    }
  }
}
</style>
