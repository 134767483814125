<template>
  <h1 v-if="content.styles.headingType === 'h1' && content.value" v-html="$sanitize(content.value)" />
  <h2 v-else-if="content.styles.headingType === 'h2' && content.value" v-html="$sanitize(content.value)" />
  <h3 v-else-if="content.styles.headingType === 'h3' && content.value" v-html="$sanitize(content.value)" />
</template>

<script>
export default {
  name: 'ContentTypeText',
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    value: false
  }),
  created () {
    this.value = this.content ? this.content.value : false
  }
}
</script>
