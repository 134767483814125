<template>
  <v-row v-if="loaded" class="mb-3">
    <v-col v-if="style" cols="12">
      <v-row>
        <v-col v-if="style.image !== ''" cols="12">
          <v-img
            v-if="style && style.image"
            class="d-none d-sm-flex"
            :src="'/images/styles/' + style.image"
            contain
            lazy-src="https://picsum.photos/id/11/100/60"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            v-if="style && style.image"
            class="d-flex d-sm-none"
            :src="'/images/styles/' + style.image"
            cover
            :aspect-ratio="8/3"
            lazy-src="https://picsum.photos/id/11/100/60"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" class="pt-0">
          {{ style.info }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DynamicPlanListHeader',
  data: () => ({
    loaded: false,
    style: false
  }),
  created () {
    this.$store.watch(
      (state, getters) => getters.getPlanCategory, (result) => {
        this.style = result
        this.loaded = true
      }, {
        immediate: true
      }
    )
  }
}
</script>
