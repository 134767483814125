<template>
  <header class="header elevation-1">
    <!-- <v-container> -->
    <div class="site-container">
      <Menu />
      <v-spacer />
    </div>
    <!-- </v-container> -->
  </header>
</template>

<script>
import Menu from './menu'
export default {
  name: 'SiteHeader',
  components: {
    Menu
  },
  data: () => ({
  }),
  created () {
  }
}
</script>
