import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: '',
    // Page Stuff ^^
    coreSettings: false,
    siteNavigation: false,
    siteLinks: false,
    navDrawerState: false,
    //
    planCategory: false,
    exchangeRates: false,
    currency: 'GBP',
    currencySymbol: '£'
  },
  mutations: {
    setPageTitle (state, pageTitle) {
      state.pageTitle = pageTitle
    },
    setCoreSettings (state, coreSettings) {
      state.coreSettings = coreSettings
    },
    setSiteNavigation (state, siteNavigation) {
      state.siteNavigation = siteNavigation
    },
    setSiteLinks (state, siteLinks) {
      state.siteLinks = siteLinks
    },
    setNavDrawerState (state, navDrawerState) {
      state.navDrawerState = navDrawerState
    },
    //
    setPlanCategory (state, planCategory) {
      state.planCategory = planCategory
    },
    setExchangeRates (state, exchangeRates) {
      state.exchangeRates = exchangeRates
    },
    setCurrency (state, currency) {
      state.currency = currency
    },
    setCurrencySymbol (state, currencySymbol) {
      state.currencySymbol = currencySymbol
    }
  },
  getters: {
    getPageTitle: state => state.pageTitle,
    //
    getCoreSettings: state => state.coreSettings,
    getSiteNavigation: state => state.siteNavigation,
    getSiteLinks: state => state.siteLinks,
    //
    getNavDrawerState: state => state.navDrawerState,
    //
    getPlanCategory: state => state.planCategory,
    getExchangeRates: state => state.exchangeRates,
    getCurrency: state => state.currency,
    getCurrencySymbol: state => state.currencySymbol
  }
})
