import axios from 'axios'

export const API_URL = '/api/front'

export default axios.create({
  baseURL: API_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
