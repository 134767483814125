<template>
  <PlanCategories
    v-if="content && content.value === 'plan-categories'"
    :content="content"
    :params="params"
  />
  <PlanList
    v-else-if="content && content.value === 'plan-list'"
    :content="content"
    :params="params"
  />
  <PlanListHeader
    v-else-if="content && content.value === 'plan-list-header'"
    :content="content"
    :params="params"
  />
  <PlanDetails
    v-else-if="content && content.value === 'plan-details'"
    :content="content"
    :params="params"
  />
  <div v-else>
    {{ content }}
  </div>
</template>

<script>
import PlanCategories from '../../dynamic/plan-categories'
import PlanList from '../../dynamic/plan-list'
import PlanListHeader from '../../dynamic/plan-list-header'
import PlanDetails from '../../dynamic/plan-details'
export default {
  name: 'DynamicContent',
  components: {
    PlanCategories,
    PlanList,
    PlanListHeader,
    PlanDetails
  },
  props: {
    content: {
      type: [Boolean, Object],
      default: false
    },
    params: {
      type: [Boolean, Object],
      default: false
    }
  },
  data: () => ({
    value: false
  }),
  created () {
    // console.log(this)
  }
}
</script>
