export default {
  methods: {
    getStyling (content) {
      const styling = {}

      if (content && content.styles) {
        const styles = content.styles
        // backgroundColour
        if (styles.backgroundColor && styles.backgroundColor !== '') {
          styling.backgroundColor = styles.backgroundColor
        }

        if (styles.padding) {
          styling.padding = styles.padding
        }

        if (styles.textAlign) {
          styling.textAlign = styles.textAlign
        }

        if (styles.fontFamily) {
          styling.fontFamily = styles.fontFamily
        }

        if (styles.border) {
          // top
          if (styles.border.top && styles.border.top.width && styles.border.top.width > 0) {
            styling.borderTopColor = styles.border.top.color
            styling.borderTopStyle = styles.border.top.line
            styling.borderTopWidth = styles.border.top.size + 'px'
          }
          // left
          if (styles.border.left && styles.border.left.width && styles.border.left.width > 0) {
            styling.borderLeftColor = styles.border.left.color
            styling.borderLeftStyle = styles.border.left.line
            styling.borderLeftWidth = styles.border.left.size + 'px'
          }
          // bottom
          if (styles.border.bottom && styles.border.bottom.width && styles.border.bottom.width > 0) {
            styling.borderBottomColor = styles.border.bottom.color
            styling.borderBottomStyle = styles.border.bottom.line
            styling.borderBottomWidth = styles.border.bottom.size + 'px'
          }
          // right
          if (styles.border.right && styles.border.right.width && styles.border.right.width > 0) {
            styling.borderRightColor = styles.border.right.color
            styling.borderRightStyle = styles.border.right.line
            styling.borderRightWidth = styles.border.right.size + 'px'
          }
        }

        // image line height
        if (content.type === 'image') {
          styling.lineHeight = 0
        }

        // Text & Headings
        if (content.type === 'text') {
          styling.color = styles.color
          styling.lineHeight = styles.lineHeight + '%'
        }

        if (content.type === 'heading') {
          styling.color = styles.color
          styling.lineHeight = styles.lineHeight / 100
        }
      }

      return styling
    }
  }
}
