<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    role="presentation"
  >
    <IconBase :icon="iconName" />
  </svg>
</template>

<script>
import IconBase from '@/components/icons/iconBase.vue'

export default {
  components: {
    IconBase
  },
  props: {
    /**
     * iconName is the name of the icon.
     *
     * @param {String}
     */
    iconName: {
      type: String,
      default: 'menu'
    },
    /**
     * width is the svg width setting.
     *
     * @param {Number, String}
     */
    width: {
      type: [Number, String],
      default: 24
    },
    /**
     * height is the svg height setting.
     *
     * @param {Number, String}
     */
    height: {
      type: [Number, String],
      default: 24
    }
  },

  computed: {
    viewbox () {
      const vb = {
        'aud-flag': '0 0 640 480',
        'cad-flag': '0 0 640 480',
        'eur-flag': '0 0 640 480',
        'gbp-flag': '0 0 640 480',
        'nzd-flag': '0 0 640 480',
        'usd-flag': '0 0 640 480'
      }
      return vb[this.iconName]
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/init';
svg {
  display: inline-block;
  vertical-align: baseline;
  // margin-bottom: -2px; /* yes, I'm that particular about formatting */
  * {
    height: auto;
    width: 100%;
  }
}

svg {

  .text--white & {
    fill: $col-text-6;
  }
}

svg.icon {
  &--white {
    fill: $col-text-6;
  }
}
</style>
